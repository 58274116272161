@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

.App {
  text-align: center;
}

.logot {
  font-family: "JetBrains Mono";
  font-size: 1.5rem;
  margin-left: 30px; /* 로고와 텍스트 사이의 간격 */
  font-weight: bold;
}

.body {
  font-family: "pretendard";
}

.App-header {
  font-family: "pretendard";
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  padding: 20px;
  color: white;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
}

.App-logo {
  height: 40px;
}

.App-header nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.App-header nav ul li {
  cursor: pointer;
  margin-left: 30px; /* 각 버튼 사이의 간격 */
}

.App-header nav ul li:last-child {
  margin-right: 2rem; /* 마지막 버튼 왼쪽으로 이동 */
}

.sections {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  margin-top: 80px; /* Adjust for fixed header height */
}

section {
  font-family: "pretendard";
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

#section5 {
  background-color: #333; /* 원하는 색상으로 변경 */
}